import { Session } from '@shared/models';

export interface VehicleBooked {
    customer_id: number;
    time?: Date | string;
    until?: Date | string;
    started?: Date | string;
    created?: Date | string;
    // privet tupomu flou
    /** @deprecated */
    model?: string;
    /** @deprecated */
    engine?: string;
    /** @deprecated */
    color?: string;
    /** @deprecated */
    transport_park?: string;
    /** @deprecated */
    price_type?: string;
    /** @deprecated */
    date_start?: number;
}

export interface VehicleModel {
    _id: string;
    enum: string;
    last_id: number;
    tank_opacity: number;
    order_last_id: number;
    image_map: string;
    image_model: string;
    images: string[];
    images_color: Record<string, string>;
    color_names: Record<string, string>;
    gallery: Record<string, string>;
    promo: Record<string, any[]>;
    number_sort: number;
    project: string;
    manufacturer: string;
    refuelInfo: string;
    integration?: {
        active?: boolean;
        modelCode?: string;
        mode?: 'genesis';
    };
    modelName: string;
}

export interface VehicleServiceAccessories {
    key: string;
    val: boolean;
}

export interface VehicleService {
    _id: string;
    accessories: VehicleServiceAccessories[];
    additional_accessories: VehicleServiceAccessories[];
    quiz_state: number;
    insurance: boolean;
    sts: boolean;
    key: boolean;
    driver_door_key: boolean;
    company: string | number;
    transport_park: string;
    user_id: number;
    vehicle_id: number;
    time_for_service: number;
    start_time: number;
    end_time: number;
    status: string;
    last_id: number;
    techPhotos: any;
}

export interface VehiclesTransportPark {
    _id: string;
    enum: string;
    address: string;
    x: string;
    y: string;
    r: string;
    last_id: number;
    order_last_id: number;
    loc: {
        type: string;
        coordinates: number[];
    };
    workday: { start: string; end: string };
    rus_name: string;
    address_en: string;
    fuelCards: string[];
    locations: string;
    country: string;
    city: string;
    cityRef: {
        name: string;
        country: string;
        ru: string;
        en: string;
    };
}

export interface VehicleDeviceData {
    geolocation: {
        type: string;
        coordinates: number[];
    };
    mileage: number;
    level_fuel: number;
    speed: number;
    doors: string; // 'CLOSE',
    height: number;
    angle_direction: number;
}

export interface VehicleInfoItem {
    sts?: string;
    equipment?: string;
    color?: string;
    engineBlocked?: string;
    engine?: string;
    id?: string;
    levelFuel?: number;
    voltageOnboart?: number;
    mileage?: number | string;
}
export interface VehicleItemStatuses {
    isSessionWaitStart: boolean;
    [status: string]: boolean;
}

export interface VehicleItem {
    _id: string;
    manufacturer: string;
    model: string;
    body_style: string;
    color: string;
    license: string;
    vin: string;
    year_issue: string;
    transport_park: string;
    sim_id: string;
    engine: string;
    equipment: string;
    enable: boolean;
    available: boolean;
    status: string;
    products: string[];
    last_id: number;
    order_last_id: string;
    sts_number: number;
    sts_serial: number;
    id20_id: number;
    booked?: VehicleBooked;
    preBooked?: VehicleBooked;
    city: string;
    statuses: VehicleItemStatuses;
    info: {
        transportParkRusName: string;
        model: string;
        delivery: any;
        delivery_address: string;
    };
    alerts: any[];
    showInCatalogOnlyFreedomEnd?: boolean;
    device?: DeviceItemDto;
    deviceRef?: DeviceItemDto;
    interior?: string;
    modelRef: VehicleModel;
    forSale?: boolean;
    catalogEnable?: boolean;
    withMileage?: boolean;
    project?: string;
    ocnCode?: string;
    interiorCode?: string;
    exteriorCode?: string;
    statusRef: string;
    vehicles_beacons: VehiclesBeacons;
}

export interface VehiclesBeacons {
    /** VIN автомобиля, обязательное поле и должно быть строкой */
    VIN: string;

    /** Процент заряда маяка 1, должен быть числом */
    prc_1: number | string;

    /** Процент заряда маяка 2, должен быть числом */
    prc_2: number | string;
    time_get_charge_1: number | string;
    time_get_charge_2: number | string;
    /** Дата создания записи, обязательное поле и должно быть датой */
    created_at: Date;
    date_update: number;
}

export interface DeviceItemDto {
    SecurityStateFlagCan: SecurityStateFlagCanDto;
    angle_direction: number;
    cell_id: number;
    charge_battery_terminal: number;
    chip_temperature: number;
    county_operator_code: number;
    device_id: string;
    device_type: number;
    doors: string;
    emergencyCodes: EmergencyCodesDto;
    engine_temperature: number;
    fuel_consumption: number;
    gps_jamming: number;
    gsm_jamming: number;
    gsm_level: number;
    gsm_level_full: number;
    gsm_registration: number;
    height: number;
    lac: number;
    latitude: number;
    level_fuel: number;
    level_fuel_acp1: number;
    level_fuel_acp2: number;
    level_fuel_acp3: number;
    level_fuel_acp4: number;
    lin_status: number;
    loc: LocDto;
    longitude: number;
    mileage: number;
    moto_hours: number;
    nfc_status: number;
    pedalPosition: PedalPositionDto;
    satellitesNumber: number;
    sensorDriving: SensorDrivingDto;
    sensorState: SensorStateDto;
    service_mileage: number;
    speed: number;
    speed_engine: number;
    terminalStatus: TerminalStatusDto;
    timegetpacket: number;
    timegps: number;
    unixtime: number;
    version: number;
    voltage_battery: number;
    voltage_onboart: number;
    week: number;
    _id: string;
}

export interface PedalPositionDto {
    pressedClutch: number;
    pressedBrake: number;
    cruiseEnabled: number;
    positionGasPedal: number;
}

export interface SecurityStateFlagCanDto {
    FootBrakeActivated: number;
    HandBrakeActivated: number;
    carClosedFactoryControlPanel: number;
    driverDoorOpen: number;
    dynamicIgnition: number;
    enginedWorked: number;
    factoryAlarmActivated: number;
    hoodOpen: number;
    ignitions: number;
    keyInIgnition: number;
    passengerAllDoorOpen: number;
    passengerFrontDoorOpen: number;
    passengerRearDoorOpen: number;
    trunkOpen: number;
    webastoOn: number;
}

export interface EmergencyCodesDto {
    abs: number;
    airbag: number;
    automaticRetarder: number;
    brakePads: number;
    chargeBatteryError: number;
    checkEngine: number;
    conditionerOn: number;
    cruiseControl: number;
    driverSeatBelt: number;
    electricalPressureAdjustment: number;
    engineTemperature: number;
    espError: number;
    fapError: number;
    handBrakeError: number;
    highBeamHeadlights: number;
    lightingError: number;
    lowBeamHeadlights: number;
    lowLevelPetrol: number;
    lowTireAirPressure: number;
    manualRetarder: number;
    oilPressureLevel: number;
    parkingLights: number;
    passengerSeatBelt: number;
    readyStartMoving: number;
    sparkPlugIndicator: number;
    stop: number;
    warning: number;
}

export interface LocDto {
    type: string;
    coordinates: string[];
}

export interface SensorDrivingDto {
    acceleration: number;
    braking: number;
    offRoad: number;
    sharpAcceleration: number;
    sharpBraking: number;
    sharpTurnLeft: number;
    sharpTurnRight: number;
    turnLeft: number;
    turnRight: number;
}

export interface SensorStateDto {
    activateEmergencyAlarm: number;
    dangerousDriving: number;
    doorControlDriver: number;
    doorControlPassenger: number;
    driverDoorStatus: number;
    engineBlocked: number;
    geoMonitoring: number;
    hoodDoorStatus: number;
    ignition: number;
    ignitionWithoutPlugs: number;
    passengerDoorStatus: number;
    roadAccident: number;
    trunkDoorStatus: number;
}

export interface TerminalStatusDto {
    defaultProfileUse: number;
    gpsAntennaConnectionError: number;
    gpsReceiverError: number;
    gpsReceiverInSleepMode: number;
    hijacking: number;
    parked: number;
    underProtection: number;
}

export interface Ocn {
    id: string;
    ocnCode: string;
    ocnName: string;
}

export interface Promo {
    photos: Photos[];
    description: string;
    mainPhoto: string;
}

export interface Manufacture {
    id: string;
    name: string;
}

export interface GenerationModeVehicle {
    id: string;
    name: string;
    promo: Promo;
    manufacture: Manufacture;
}

export interface Photos {
    img: string;
    description: string;
    thumb?: string;
}

export interface Generation {
    id: string;
    name: string;
    fuelTank: number;
    modelParams: object;
    promo: Promo;
    model: GenerationModeVehicle;
}

export interface Options {
    list: OptionsItem[];
}

export interface OptionsItem {
    id?: string;
    name: string;
    externalId?: number;
    category: string;
}

export interface CategoryOptions {
    name: string;
    options: OptionsItem[];
}

export interface EquipmentDTO {
    id: string;
    name: string;
    promo: Promo;
    options: Options;
}

export interface Equipment {
    id: string;
    name: string;
    promo: Promo;
    categoryOptions: CategoryOptions[];
}

export interface LocaleText {
    en: string;
    ru: string;
}

export interface Transmission {
    id: string;
    name: string;
    i18n: LocaleText;
}

export interface EngineFuelType {
    id: string;
    name: string;
    i18n: LocaleText;
}

export interface DriveType {
    id: string;
    name: string;
}

export interface EngineModification {
    id: string;
    name: string;
    volume: number;
    description: string;
    engineFuelType: EngineFuelType;
}

export interface Modification {
    id: string;
    calculationName: string;
    power: string;
    description: LocaleText;
    fuelConsumption: number;
    fuelConsCity: number;
    fuelConsHw: number;
    seats: number;
    transmission: Transmission;
    engine: EngineModification;
    driveType: DriveType;
}

export type PackagesPromo = Omit<Promo, 'description' | 'mainPhoto'>;

export interface PackageDTO {
    name: string;
    description: string;
    promo: PackagesPromo;
    mainPhoto?: string;
    options: Options;
}

export interface Package {
    name: string;
    description: string;
    promo: PackagesPromo;
    mainPhoto?: string;
    categoryOptions: CategoryOptions[];
}

export interface Render {
    id: string;
    renderConditionId: string;
    urls: string[];
    color: CatalogExterior;
    view: RenderView;
}

export interface RenderView {
    viewId: string;
    viewName: string;
    viewCode: string;
}

export interface CatalogOcnInformation {
    ocn: Ocn;
    generation: Generation;
    equipment: EquipmentDTO;
    modification: Modification;
    packages: PackageDTO[];
    renders: Render[];
}

export interface CatalogExterior {
    id: string;
    generationId: string;
    name: string;
    hexColor: string;
    i18n: LocaleText;
    colorCode: string;
    hexCode: string | null;
}

export interface CatalogInterior {
    id: string;
    generationId: string;
    name: string;
    i18n: LocaleText;
    colorCode: string;
    materialId: string;
    interiorRender: string;
    upholsteryRender: string;
    hexCode: string;
}

export interface Damage {
    approval: {
        timestamp?: string;
        who?: {
            email?: string;
        };
    };
    class: string;
    company: string;
    date: number;
    description: string;
    last_customer_id: number;
    last_id: number;
    part: string;
    photo: string[];
    service_id: number;
    size: string;
    status: string;
    transport_park: string;
    type: string;
    pdf: string[];
    userFio: string;
    user_id: number;
    customerId?: number;
    vehicle_id: number;
    x: number;
    y: number;
    _id: string;
}

export interface VehicleItemDto extends VehicleItem {
    prebooking?: string;
    equipment_name?: string;
    color_interior?: string;
    modelRef: VehicleModel;
    damages: Damage[];
    statusRef: string;
    service: VehicleService;
    fuel: number;
    mileage: number;
    doors: string;
    voltage_onboart: number;
    timegetpacket: Date | string;
    lastServices: VehicleService[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    transport_parkRef: VehiclesTransportPark;
    tasks: any[];
    session: Session;
    dataEquipment?: any;
    dataColor?: any;
    dataEngine?: any;
    engineRef?: EngineDto;
    equipmentRef?: EquipmentDto;
    interiorRef?: InteriorDto;
    fuel_type: string;
    sts_registration_date: Date | string;
    catalogOcnInformation: CatalogOcnInformation;
    ocnCode: string;
    exteriorCode: string;
    interiorCode: string;
    catalogExterior: CatalogExterior;
    catalogInterior: CatalogInterior;
    withMileage?: boolean;
    catalogEnable?: boolean;

    extendTelematic: {
        telematicType: VehicleExtendTelematicTypeEnum;
        status: VehicleExtendTelematicStatus;
    };
}

export interface VehicleExtendTelematicStatus {
    name: string;
    code: VehicleExtendTelematicStatusEnum;
    color: string;
}

export enum VehicleExtendTelematicStatusEnum {
    ACTIVE = 'ACTIVE',
    DEACTIVE = 'DEACTIVE',
    SENDED = 'SENDED',
    PROBLEM = 'PROBLEM',
}

export enum VehicleExtendTelematicTypeEnum {
    HYUNDAI = 'Bluelink',
    GENESIS = 'GCS',
}
export interface EquipmentDto {
    equipment_name: string;
}

export interface InteriorDto {
    color: string;
}

export interface VehicleDto {
    vehicle: VehicleItemDto;
    sessions: Session[];
    damageRef?: any;
}

export interface EngineDto {
    caption: string;
    description: string;
    displacement: string;
    drivetrain: string;
    engVol: string;
    engine_type: string;
    enum: string;
    equipment_id: string;
    fuel_economy: string;
    fuel_type: string;
    last_id: string;
    modelId: string;
    model_id: string;
    order_last_id: string;
    power: string;
    seat: number;
    status: boolean;
    title: string;
    transmission: string;
}
